import React, {useEffect} from 'react';

const PaymentRequest = ({paymentData, inGame=false}) => {

    useEffect(() => {
        // Load INIStdPay script
        const script = document.createElement('script');
        script.src = 'https://stdpay.inicis.com/stdjs/INIStdPay.js';
        script.charset = 'UTF-8';
        script.onload = () => {
            // Initialize INIStdPay when the script is loaded
            if(paymentData){
                if (window.INIStdPay) {
                window.INIStdPay.pay('SendPayForm_id');
            }
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [paymentData]);


    return (
        <div>
            <form id="SendPayForm_id" name="" method="POST">
                <input type="hidden" name="version" value={paymentData.version}/>
                <input type="hidden" name="mid" value={paymentData.mid}/>
                <input type="hidden" id="goodsname" name="goodsname" value={paymentData.goodsName}/>
                <input type="hidden" name="oid" value={paymentData.orderNumber}/>
                <input type="hidden" id="price" name="price" value={paymentData.price}/>
                <input type="hidden" name="currency" value="WON"/>
                <input type="hidden" name="buyername" value={paymentData.userId}/>
                <input type="hidden" name="buyertel" value={paymentData.phone}/>
                <input type="hidden" name="buyeremail" value={paymentData.email}/>
                <input type="hidden" name="timestamp" value={paymentData.timestamp}/>
                <input type="hidden" name="signature" value={paymentData.signature}/>
                <input type="hidden" name="mKey" value={paymentData.mKey}/>
                <input type="hidden" name="gopaymethod" value={paymentData.goPayMethod}/>
                <input type="hidden" name="offerPeriod" value="2015010120150331"/>
                <input
                    type="hidden"
                    name="acceptmethod"
                    value="HPP(1):no_receipt:va_receipt:vbanknoreg(0):below1000"
                />
                <input type="hidden" name="languageView" value=""/>
                <input type="hidden" name="charset" value=""/>
                <input type="hidden" name="payViewType" value="overlay"/>

                {inGame ?
                    <input type="hidden" name="returnUrl"
                           value={"https://" + window.location.hostname + "/api/v1/pay/in_game_pay_return/"}/>
                    :
                    <input type="hidden" name="returnUrl"
                           value={"https://" + window.location.hostname + "/api/v1/pay/pay_return/"}/>

                }

                {/*<input type="hidden" name="closeUrl" value={"https://" + window.location.hostname + "/ingame/shop"}/>*/}

                <input type="hidden" name="nointerest" value={paymentData.cardNoInterestQuota}/>
                <input type="hidden" name="quotabase" value={paymentData.cardQuotaBase}/>
                <input type="hidden" name="INIregno" value=""/>
                <input type="hidden" name="merchantData" value={paymentData.merchantData}/>
            </form>
        </div>
    );
};

export default PaymentRequest;
