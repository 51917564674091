import React, {useEffect} from 'react';
import AddToken from "../authentication/token";


const WithdrawInGame = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        AddToken(params.get('token'));
        window.location.href = '/my_page?page=withdraw';
    }, []);

    return ;
};

export default WithdrawInGame;
