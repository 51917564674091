import SubHeader from "../home/subHeader";
import "../css/shop.css";
import RightGames from "../game/rightGames";
import React, {useEffect, useState} from "react";
import icon_button from "../images/mypage_img/Icon-button.png";
import $ from 'jquery';
import SubFooter from "../home/subFooter";
import PaymentRequest from "../payment/paymentRequest";
import {useNavigate} from "react-router-dom";


function numberToKorean(number) {
    var avatarQuantity = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (avatarQuantity % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}

function InGameBuyAvatarOK(props) {
    const navigate = useNavigate();
    const [reward, setReward] = useState('');
    const [avatarQuantity, setAvatarQuantity] = useState(1);
    const [paymentData, setPaymentData] = useState(null);

    const avatarId = localStorage.getItem('avatarId');
    const avatarPrice =  localStorage.getItem('avatarPrice');
    const avatarName =  localStorage.getItem('avatarName');
    const avatarCount =  localStorage.getItem('avatarCount');

    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        inputValue = inputValue.replace(/[^0-9\s]/g, '');
        if (inputValue){
            const newValue = Math.min(Math.max(parseInt(inputValue || 0), 1), 70);
            setAvatarQuantity(newValue);
        } else {
            setAvatarQuantity(inputValue);
        }

    };


    useEffect(() => {
        $('.Rightwarp_moneyinfoBox').css('display', 'none');
        $('#RightWarpCashORfree').css('display', 'none');
        $('.right_warp').css('height', '541px');

        const reward = localStorage.getItem('reward');

        setAvatarQuantity(avatarCount);
        setReward(reward);

    }, []);


    return (
        <>
            <SubHeader bodyColor={false}/>
            <div className="TopMysection">
                <div className="TopMysection_inner" style={{justifyContent: 'left', maxWidth: '1400px'}}>
                    <div className="shop-tab">
                        <a className="tablinks" href="../shop" >
                            <div className="shop-top-my-section-title">상점</div>
                        </a>
                        <a className="tablinks" href="../shop">
                            <div className="shop-top-my-section-tit">아바타</div>
                        </a>
                    </div>
                </div>
            </div>

            <div className="ShopGameCenterwarpMain" >
                <div className="GameCenterwarp">
                    <div className="GameCenterwarp_inner">
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"></div>
                            <div className="subMaintitle"><em>아바타
                                구매</em> {/*<button className="My_contents_changebutton" onClick={ReadyPopup} style={{margin: '-2px 0 0 18'}}>캐시충전</button>*/}
                            </div>
                            <div className="subMainDepth">상점 &gt; 아바타구매</div>
                        </div>


                        <div className="Buy_section">
                            <div className="Buy_contents">
                                <div className="Buy_AvartaImg">
                                    <img src={require('../images/shop_img/' + avatarId + '.gif')} alt="Avatar"/>
                                </div>
                                <div className="Buy_contentsInner">
                                    <div className="Buy_TitleBox" style={{borderBottom: '2px solid #4e4e4e'}}>
                                        {avatarName}
                                    </div>
                                    <div className="Buy_ContentsBox">
                                        <div className="Buy_ContentsTitleBox">수량</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div className="Countnumber">
                                                <input type="text" id="numberUpDown" value={avatarQuantity}
                                                       onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="Buy_ContentsBox">
                                        <div className="Buy_ContentsTitleBox">게임머니</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div style={{marginTop: '13px'}}>
                                                <em id="GetGold">{numberToKorean(reward * avatarQuantity)} 골드(냥) 지급</em>
                                            </div>
                                            <input type="hidden" id="PHPreward" value={reward}/>
                                        </div>
                                    </div>

                                    <div className="Buy_ContentsBox">
                                        <div className="Buy_ContentsTitleBox">결제금액</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div id="BuyPayment"
                                                 style={{marginTop: '13px'}}>{new Intl.NumberFormat().format(avatarPrice * avatarQuantity)}원
                                            </div>
                                            <input type="hidden" id="PHPBuyPayment"
                                                   value={avatarPrice * avatarQuantity}/>
                                        </div>
                                    </div>

                                    <div className="BuyMoneyTextBox">
                                        <div className="BuyMoneyTextBoxInner" style={{lineHeight: '2'}}>
                                            <div className="BuyMoneyTextBoxInner2">
                                                구매가 완료 되었습니다.<br/>
                                                내지갑에서 사용한 아바타는 환불이 불가능합니다. <br/>
                                                좋은시간 되세요~
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="quit_oknoBox_button">
                                            <button className="quit_okno_button" type="button"
                                                    onClick={() => navigate('/shop')}>
                                                확인
                                                <div className="quit_okno_button_img">
                                                    <img src={icon_button} alt="취소"/>
                                                </div>
                                            </button>
                                            <button className="quit_okno_button" type="button"
                                                    onClick={() => navigate('/my_page?page=myWallet')}
                                                    style={{backgroundColor: '#4e4e4e'}}>
                                                내지갑으로
                                                <div className="quit_okno_button_img">
                                                    <img src={icon_button} alt="취소"/>
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <RightGames/>
                </div>
            </div>

            {paymentData && <PaymentRequest paymentData={paymentData}/>}

            <SubFooter/>
        </>
    );
}

export default InGameBuyAvatarOK;