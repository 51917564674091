import React, {useEffect} from "react";
import ShopAvatar from "./shopAvatar";
import AddToken from "../authentication/token";
import '../css/inGameShop.css';
import $ from 'jquery';


function InGameShop() {
    const params = new URLSearchParams(window.location.search);
    var status = params.get('message');
    var token = params.get('token');


    useEffect(() => {
        $('body').css("background-color", "#000000");
        if (token) {
            AddToken(token);
            localStorage.setItem('gameName', 'sm_smpl');
        }

        if (status) {
            alert(status);
        }
    }, [token, status]);

    return (
        <div className="ShopGameCenterwarpMain" style={{backgroundColor: "#000000", color: "white"}}>
            <div className="ShopGameCenterWarpInner" style={{marginTop: '40px', width: "1210px"}}>
                <div className="subMaintitleBox" style={{backgroundColor: "#333", color: "white", border: "unset"}}>
                    <div className="subMaintitle"><em>아바타
                        구매</em>
                    </div>

                    {/*<div*/}
                    {/*    id="main_avatars"*/}
                    {/*    className="shop-top-my-section-tit"*/}
                    {/*    onClick={() => window.location.href = "../ingame/shop"}*/}
                    {/*    style={{color: page ? "#7b7b7b": "rgb(21, 163, 225)"}}*/}
                    {/*>*/}
                    {/*    아바타*/}
                    {/*</div>*/}

                    {/*<div*/}
                    {/*    id="smpl_avatars"*/}
                    {/*    className="shop-top-my-section-tit"*/}
                    {/*    onClick={() => window.location.href = "../ingame/shop?page=smpl_avatars"}*/}
                    {/*    style={{color: page ? "rgb(21, 163, 225)": "#7b7b7b"}}*/}
                    {/*>*/}
                    {/*    텍사스홀덤(SMPL)*/}

                    {/*</div>*/}
                </div>

                <ShopAvatar/>

            </div>
        </div>
    );

}

export default InGameShop;